

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";

@Component({ components: { Datepicker, VueTimepicker } })
export default class reportSettingComponent extends Vue {

  
  public name: any = null;
  public date: any = null;
  public holiday = [];
  public holidayDateExistenceError = null;
  public weekends = [];
  public identifier :string = 'businessHour';
  public reportTimings = [];
  public arrayOfChangedTimeReport = [];
  //- Section Loaders -----------------------------------------------------------------------------
  public fetchReportTimingsLoader :Boolean =null;
  public fetchWeekendsLoader :Boolean =null;
  public fetchHolidaysLoader :Boolean =null;
  public currentReportTimingsTab :String =null;
  
  
/**************************************************************************************************
*                                 ON SUBMITTING A NEW HOLIDAY                                     *
***************************************************************************************************
 * Function Working Progress ---
 * From public name&date var it gets the user input values of holiday
 * after validate required fields request send to server 
 * if the response status code is 200 holiday is added.
 * if the response status code is 409 holiday date is already exists. 
 **/
  public async addHoliday() {
    try {
      let body = {
        name: this.name,
        date:this.date.getFullYear() +"-" +(this.date.getMonth() + 1) + "-" + this.date.getDate()
      };
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      let response = await Axios.post(BASE_API_URL + "superAdmin/addHoliday",body);
      if (response.data.status == 409) {
        this.holidayDateExistenceError = true;
      } else {
        this.fetchHolidays();
        this.$modal.hide('addHolidayModal')
        this.name = null;
        this.date = null;
        this.$snotify.success("Holiday added successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }


/**************************************************************************************************
*                                 ON MOUNTED FOR FETCHING HOLIDAYS                                *
***************************************************************************************************
 * Function Working Progress ---
 * Just Hitting Server API and response data is storing in @var { holiday }.
 **/
  public async fetchHolidays() {
    try {
      this.fetchHolidaysLoader = true;
      let response = await Axios.get( BASE_API_URL + "superAdmin/fetchHolidays");
      this.holiday = response.data;
      this.fetchHolidaysLoader = false;
    } catch (error) {
      this.fetchHolidaysLoader = false;
      console.log(error);
    }
  }


/**************************************************************************************************
*                                 ON CLICKING IN BUSINESS OR REGULAR TABS                         *
***************************************************************************************************
 * Function Working Progress ---
 * from html it gets the @param {identifier}
 * and Calls the API of fetching data of timings of weeks
 **/
  public async toggleWeekdays(identifier){
    try {
      if (this.identifier != identifier){
        this.identifier = identifier;
        await this.fetchWeekends();
      }
    } catch (error) {
      console.log(error);
    }
  }
/**************************************************************************************************
*                                 ON MOUNTED TO FETCH WEEK TIMINGS                                *
***************************************************************************************************
 * Function Working Progress ---
 * Hits the Server with @identifier {businessHour/regular}
 * in response it gets the data which is in object form 
 * from object it gets convert into array and saved into @var {weekends}.
 * Converting process ---
 * in object where the key is 0 to 6 its actually the day so we call
 * a @function getDayName from number it converts in day Name 
 * we are making a object having key name, holiday & timings
 * name stores name of Day Name,
 * whereas, holiday stores a boolean value and
 * timings is dependent on holiday if holiday then 
 * the property will exist otherwise No .
 * and pushing it into our @var {weekends}.
 **/
  public async fetchWeekends() {
    try {
      this.fetchWeekendsLoader = true;
      this.weekends = [];
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/fetchWeekends",{identifier:this.identifier});
      Object.values(response.data).forEach((value, key) => {
        let data: any = {
          name: this.getDayName(key),
          holiday: value ? false : true
        };
        if (value != null) data.timings = value;
        this.weekends.push(data);
      });
      this.fetchWeekendsLoader = false;
    } catch (error) {
      this.fetchWeekendsLoader = false;
      console.log(error);
    }
  }
/**************************************************************************************************
*                                 ON CLICKING ON SAVE BUTTON SECTION(WEEKENDS)                    *
***************************************************************************************************
 * Function Working Progress ---
 * It get started with the array which converts into object,
 * as the same structure of db.
 * Also its validate the timings as Ending time 
 * should not be less than starting time.
 * Sends request to Server is response status is 200
 * data is saved.
 **/
   public async saveWeekends() {
    try {
      let body :any= {
        identifier: this.identifier
      };
      let value= {};
      let validate =true;
      this.weekends.forEach((day,i) => {
        validate = (day.timings&&day.timings[0]>=day.timings[1])?false:validate;
        validate = day.timings&&(day.timings[0]==''||day.timings[1]=='')?false:validate;
        value[i] = day.holiday?null:[day.timings[0],day.timings[1]];
      });
      if(!validate){
        this.$snotify.info('Please Choose right timings');
        return;
      }
      body.value = value;
      let response = await Axios.post(BASE_API_URL + "superAdmin/saveWeekends",body);
      this.$snotify[response.data.status==200?'success':'error'](response.data.message);
    } catch (error) {
      console.log(error);
    }
  }
/**************************************************************************************************
*                              ON CLICKING ON HOLIDAY CHECKBOX IN WEEK TIMINGS                    *
***************************************************************************************************
 * Function Working Progress ---
 * When Clicked it gets @param {data}
 * which is array element in form of object
 * so we reverse the data.holiday value
 * and delete or add the property timings 
 * according to our condition.
 **/
  public toggleWeekend(data) {
    if (data.holiday) {
      data.holiday = false;
      data.timings = ["10:00:00", "18:00:00"];
    } else {
      data.holiday = true;
      delete data.timings;
    }
  }
/**************************************************************************************************
*                          ON MOUNTED FOR FETCHING THE DATA OF REPORTS TIMINGS                    *
***************************************************************************************************
 * Function Working Progress ---
 * Just hitting the API and gets the data 
 * array ans store in @var {reportTimings}.
 **/
  public async fetchReportTimings(category){
    try {
      if(category != this.currentReportTimingsTab){
        this.arrayOfChangedTimeReport=[];
        this.currentReportTimingsTab = category;
        this.fetchReportTimingsLoader = true;
        let response = await Axios.post( BASE_API_URL + "superAdmin/fetchReportTimings",
        {reportCategory:category});
        this.reportTimings = response.data;
        this.fetchReportTimingsLoader = false;
      }
    }  
    catch (error) {
      this.fetchReportTimingsLoader = false;
      console.log(error);
    }
  }
/**************************************************************************************************
*                               ON CHANGING ANY FIELD OF REPORT TIMINGS                           *
***************************************************************************************************
 * Function Working Progress ---
 * it gets the functionName in params 
 * so we fetch the current value from our @var reportTimings
 * and push it to @var arrayOfChangedTimeReport also 
 * if someone change the same field it gets filtered .
 **/
  public changeReportTimings(functionName){
    try {
      this.arrayOfChangedTimeReport = this.arrayOfChangedTimeReport.filter(a=>a.name!=functionName);
      this.arrayOfChangedTimeReport.push(this.reportTimings.find(d=>d.name==functionName))
    } catch (error) {
      console.log(error);
    }
  }

/**************************************************************************************************
*                            ON CLICKING ON SAVE BUTTON SECTION(WEEKENDS)                         *
***************************************************************************************************
 * Function Working Progress ---
 * If the @var arrayOfChangedTimeReport is not 
 * empty it sends the request with the upper mentioned var
 * and if response comes with status code is 200
 * its empty the @var arrayOfChangedTimeReport otherwise 
 * its doesn't.
 **/
  public async saveReportTimings(){
    try {
      this.$snotify.clear();
      if(this.arrayOfChangedTimeReport.length==0){
        this.$snotify.info('No changes done')
        return;
      }
      let response = await Axios.post(BASE_API_URL+'superAdmin/saveReportTimings',{data:this.arrayOfChangedTimeReport},
        {});
      this.$snotify[response.data.status==200?'success':'error'](response.data.message);
      this.arrayOfChangedTimeReport=response.data.status==200?[]:this.arrayOfChangedTimeReport;
    } catch (error) {
      console.log(error);
    }
  }

   
/**************************************************************************************************
*                                   FROM @FUNCTION fetchweekends                                  *
***************************************************************************************************
 * Function Working Progress ---
 * Simple Switch Case
 **/
  public getDayName(day) {
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
    }
  }

  public functionGenericName = {
    'getLoanSetupReport' : "Loan Setup Not Complete",
    'getRunAusReport' :"Run Aus Not Complete",
    'getAusHoldReport' :"Loan in Run Aus Hold",
    'getInitialDisclosuresReport' :"Initial DIsclosure Not Sent",
    'getUWResponseSecondReport' :"UW Response Second Not Created",
    'getAppraisalReport' :"Appraisal Task Not Completed",
    'getTitleReport' :"Title Task Not Completed",
    'getVOEReport' :"VOE Task Not Completed",
    'getClosingDisclosureNotSend':"Closing Disclosure Not Send",
    'getClosingDisclosureNotSign' :"Closing Disclosure Not Sign",
    'getAppraisalOrderedReport'  :"Appraisal Ordered Task Not Completed",
    'getAppraisalReceivedReport' :"Appraisal Received Not Completed",
    'getTitleOrderedReport' :"Title Ordered Not Completed",
    'getTitleReceivedReport' :"Title Received Not Completed",
    'getConditionApprovalReport':"Condition Approval Task Not Completed",
    'getCTCClosingReport':"CTC Task Not Completed",
    'getUWSubmittedReport':'UW Submitted Task',
    'getRateLockReport':'Rate Not Locked',
    'getRateExpirationReport':"Rate Lock Expiration Date",
    'getBorrowerCommunicationReport':"No Borrower Communication",
    'noBorrowerCommunicationForLoanSetup':"No Borrower Communication for Loan Setup",
    'noBrokerCommunicationForLoanSetup' :"No Broker Communication for Loan Setup",
    'noBorrowerCommunicationForIDS' :"No Borrower Communication for Initial Disclosure",
    'noBorrowerCommunicationForCA' :"No Borrower Communication for Conditional Approval",
    'noBorrowerCommunicationForUWResponse':"No Borrower Communication in UW Response",
    'noBorrowerCommunicationForCD' :"No Borrower Communication for CD issued",
    'noBorrowerCommunicationForCTC' :"No Borrower Communication for CTC issued",
    'inBoundCommunication' :"No InBound Communication",
  }

/**************************************************************************************************
*                                           MOUNTED FUNCTION                                      *
**************************************************************************************************/
  async mounted() {
    await this.fetchHolidays();
    await this.fetchWeekends();
    await this.fetchReportTimings('Closing');
  }
}
